<template>
  <div>
    <!-- 公告 header -->
    <div class="indexUse_header">
      <div class="wrap headerBox">
        <div @click="Goindex()" class="logo">
          <img src="../../../assets/image/funuo.png" alt />
          孚诺大药房
        </div>
        <div class="nav">
          <div @click="$router.push('/')">首页</div>
          <div
            @click="headertab1()"
            @mouseover="headerTab = 1"
            @mouseout="headerTab = -1"
            :class="{userArrActives : headerTab ==1}"
          >个人中心</div>
          <div
            @mouseover="headerTab = 2"
            @mouseout="headerTab = -1"
            :class="{userArrActives : headerTab ==2}"
          >
            服务
            <img v-show="headerTab != 2" src="@/assets/image/top_Open_icon@2x.png" alt />
            <img v-show="headerTab == 2" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab ==2"></i>
            <div v-show="headerTab ==2" class="server">
              <div
                @mouseover="userArrActive = i"
                @mouseout="userArrActive = -1"
                @click="navTab(v.state)"
                :class="{userArrActive : userArrActive ==i}"
                v-for="(v,i) in userArr"
                :key="i"
                class="list"
              >
                <img v-show="userArrActive ==i" :src="v.img1" alt />
                <img v-show="userArrActive !=i" :src="v.img2" alt />
                {{v.txt}}
              </div>
            </div>
          </div>
          <div
            @mouseover="headerTab = 3"
            @mouseout="headerTab = -1"
            :class="{userArrActives : headerTab ==3}"
          >
            消息
            <img v-show="headerTab != 3" src="@/assets/image/top_Open_icon@2x.png" alt />
            <img v-show="headerTab == 3" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab == 3"></i>
            <div v-show="headerTab == 3" class="server news">
              <p class="one" @click="GoNews(newsList)">{{newsList.title}} : {{newsList.content}}</p>
              <p class="goAllNews" @click="GoNews()">查看全部消息</p>
            </div>
          </div>
        </div>
        <div
          class="ffr"
          @mouseover="headerTab = 4"
          @mouseout="headerTab = -1"
          :class="{userArrActives : headerTab ==4}"
        >
          <div class="ffrs">
            {{userList.user_nickname}}
            <img
              v-show="headerTab != 4"
              src="@/assets/image/top_Open_icon@2x.png"
              alt
            />
            <img v-show="headerTab == 4" src="@/assets/image/top_Retract@2x.png" alt />
            <i v-show="headerTab == 4"></i>
            <div v-show="headerTab == 4" class="server">
              <div
                @click="userTab(i)"
                @mouseover="userArrActive = i"
                @mouseout="userArrActive = -1"
                :class="{userArrActive : userArrActive ==i}"
                v-for="(v,i) in myArr"
                :key="i"
                class="list"
              >
                <img v-show="userArrActive ==i" :src="v.img1" alt />
                <img v-show="userArrActive !=i" :src="v.img2" alt />
                {{v.txt}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="indexUse_header_min">
      <div class="wrap">
        <!-- <img class="myHeaderImg fl" src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" alt /> -->
        <img
          class="myHeaderImg fl"
          v-if="!isDefalutImg"
          :src="userList.avatar"
          @error="isDefalutImg = !isDefalutImg"
        />
        <img
          class="myHeaderImg fl"
          v-else
          src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
          alt
        />
        <div class="fl myName">
          <p>{{userList.user_nickname}}</p>
          <p>
            <img @click="user1()" src="@/assets/image/top_Basicinformation_icon@2x.png" alt />
            <img @click="user2()" src="@/assets/image/top_security_icon@2x.png" alt />
            <img @click="user3()" src="@/assets/image/top_pen_icon@2x.png" alt />
          </p>
        </div>
        <div class="fl fn">
          <p @click="tab1()">我关注的医生 ： {{ expertTotal }}</p>
          <p @click="tab2()">我收藏的商品 ： {{ favoriteList.length }}</p>
        </div>
        <div class="fr fns fn">
          <p @click="tab3()">
            <img src="@/assets/image/top_Fraction_icon@2x.png" alt />
            {{userList.score}}
          </p>
          <p @click="tab4()">
            <img src="@/assets/image/top_wallet_icon@2x.png" alt />我的钱包
          </p>
        </div>
      </div>
    </div>
    <el-dialog title :visible.sync="dialogVisible" width="30%">
      <span>确定要退出？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="removeToeken()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { usergetInfo } from "@/request/user";
// import { usergetinfo } from "@/request/user";
import { message } from "@/request/user";
import { mapState, mapMutations } from "vuex";
import { getFavoriteList } from "@/request/index";
import { getAttentionList } from "@/request/user";

export default {
  props: ["userList"],
  data() {
    return {
      headerTab: -1,
      nav: -1,
      dialogVisible: false,
      newsList: [],
      isDefalutImg: false,
      favoriteList: [],
      expertTotal: 0,
      userArr: [
        {
          txt: "我的问诊",
          state: "/user/Interrogation",
          img1: require("@/assets/image/top_Open_Interrogation_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Interrogation@2x.png")
        },
        {
          txt: "我的医生",
          state: "/user/myDoctor",
          img1: require("@/assets/image/top_Open_Doctor_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Doctor@2x.png")
        },
        {
          txt: "我的用药",
          state: "/user/myDrug",
          img1: require("@/assets/image/top_Open_Medication_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Medication@2x.png")
        },
        {
          txt: "我的档案",
          state: "/user/archives",
          img1: require("@/assets/image/top_Open_archives_hover@2x.png"),
          img2: require("@/assets/image/top_Open_archives@2x.png")
        },
        {
          txt: "我的订单",
          state: "/user/allOrder",
          img1: require("@/assets/image/top_Open_Order_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Order@2x.png")
        },
        {
          txt: "我的处方",
          state: "/user/prescribe",
          img1: require("@/assets/image/top_Open_prescription_hover@2x.png"),
          img2: require("@/assets/image/top_Open_prescription@2x.png")
        }
      ],
      myArr: [
        {
          txt: "账户设置",
          state: 0,
          img1: require("@/assets/image/top_Open_Setup_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Setup@2x.png")
        },
        {
          txt: "退出",
          state: 1,
          img1: require("@/assets/image/top_Open_Signout_hover@2x.png"),
          img2: require("@/assets/image/top_Open_Signout@2x.png")
        }
      ],
      userArrActive: -1 //样式切换 TOP栏
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    this.shop_id = localStorage.getItem("lzyyShopId");
    if (datas != null) {
      this.$tokens = datas;
      this.$user.state = 1;
      this.start();
    } else {
      this.$user.state = 0;
    }
  },
  computed: {
    ...mapState(["sendMessage"])
  },
  methods: {
    removeToeken() {
      this.dialogVisible = false;
      localStorage.removeItem("lzyyToken");
      this.$tokens = "";
      this.$user.state = 0;
      this.$router.replace({ path: "/" });
      this.closeSocket();
    },
    closeSocket() {
      let obj = { method: "logout" };
      this.sendMessage(JSON.stringify(obj));
      this.setLoginSocket(false);
    },
    navTab(e) {
      this.$router.replace({ path: e });
    },
    start() {
      message({
        token: this.$tokens
      }).then(res => {
        let datas = res.data;
        let arr = [];
        for (let i = 0; i < datas.length; i++) {
          let datass = datas[i];
          if (datass.new_message != null) {
            arr = datass.new_message;
          }
        }
        this.newsList = arr;
        if(this.newsList.type == 3) {
          this.newsList.content = JSON.parse(this.newsList.content).message
        }
      });

      getFavoriteList({
        token: this.$token,
        shop_id: this.shop_id
      }).then(res => {
        if (res.code == 1) {
          this.favoriteList = res.data.data;
        }
      });
	this.$axios.post("/api/user_fav/getAttentionList",{token:this.$token}).then(res=>{
		  this.expertTotal = res.data.data.total;
	})
    //   //   关注的专家
    //   getAttentionList({
    //     token: this.$token,
    //     type: 2
    //   }).then(res => {
		  // console.log(res);
    //     if (res.code == 1) {
    //       this.expertTotal = res.data.total;
    //     }
    //   });
    },
    userTab(i) {
      if (i == 1) {
        this.dialogVisible = true;
      } else {
        let path = this.$route.path;
        if (path.indexOf("userData") != -1) {
          this.$emit("tab1");
        } else {
          this.$router.replace({ path: "/user/userData?state=0" });
        }
      }
    },
    GoNews(newsList) {
      if (newsList) {
        this.$router.replace({ path: "/user/news?state=" + newsList.type });
      } else {
        this.$router.replace({ path: "/user/news?state=2" });
      }
    },
    // 去  用户 首页
    Goindex() {
      this.$router.replace({ path: "/" });
    },
    headertab1() {
      this.$router.replace({ path: "/user/index" });
    },
    tab1() {
      // 我关注的医生
      this.$router.replace({ path: "/user/myDoctor" });
    },
    tab2() {
      // 我收藏的商品
      this.$router.replace({ path: "/user/goods" });
    },
    tab3() {
      //  积分
      this.$router.replace({ path: "/user/integral" });
    },
    tab4() {
      // 钱包
      this.setWalletState(0);
      this.$router.replace({ path: "/user/wallet" });
    },
    user1() {
      let path = this.$route.path;
      if (path.indexOf("userData") != -1) {
        this.$emit("tab1");
      } else {
        this.$router.replace({ path: "/user/userData?state=0" });
      }
    },
    user2() {
      let path = this.$route.path;
      if (path.indexOf("userData") != -1) {
        this.$emit("tab2");
      } else {
        this.$router.replace({ path: "/user/userData?state=1" });
      }
    },
    user3() {
      let path = this.$route.path;
      if (path.indexOf("userData") != -1) {
        this.$emit("tab3");
      } else {
        this.$router.replace({ path: "/user/userData?state=3" });
      }
    },
    ...mapMutations(["setLoginSocket", "setWalletState"])
  }
};
</script>
<style lang="less" scoped>
// @import "./index";
.indexUse_header {
  cursor: Pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  line-height: 66px;
  background: white;
  z-index: 9;
  .headerBox {
    display: flex;
    position: relative;
  }
  .logo {
    font-size: 24px;
    img {
      width: 44px;
      height: 44px;
      float: left;
      margin-top: 10px;
      margin-right: 12px;
    }
  }
  .nav {
    display: flex;
    margin-left: 110px;
  }
  .nav > div {
    text-align: center;
    width: 120px;
    position: relative;
    cursor: pointer;
    i {
      position: absolute;
      display: block;
      bottom: -10px;
      left: 50px;
      width: 20px;
      height: 20px;
      background: white;
      transform: rotate(45deg);
      z-index: 1;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
    }
  }
  .server {
    position: absolute;
    top: 66px;
    width: 176px;
    left: -28px;
    z-index: 2;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
  }
  .server > .list {
    color: #000;
    cursor: pointer;
    height: 58px;
    line-height: 58px;
    font-size: 16px;
    img {
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }
  }
  .userArrActives {
    color: #4eeec8;
  }
  .userArrActive {
    color: #4eeec8 !important;
  }
  .news {
    color: #000;
    width: 364px;
    // height: 150px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    p {
      background: #f5f5f5;
      text-align: left;
      font-size: 14px;
      height: 42px;
      line-height: 42px;
      padding-left: 10px;
      margin-bottom: 2px;
      cursor: pointer;
    }
    .goAllNews {
      margin-top: 10px;
      background: white;
      color: #4eeec8;
      text-align: center;
    }
  }
  .ffr {
    position: absolute;
    top: 0;
    right: 0;
    img {
      vertical-align: middle;
    }
    > .ffrs {
      position: relative;
    }
    i {
      position: absolute;
      display: block;
      bottom: -10px;
      left: 22px;
      width: 20px;
      height: 20px;
      background: white;
      transform: rotate(45deg);
      z-index: 1;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.1);
    }
    .server {
      text-align: center;
      left: -58px !important;
    }
  }
}

// header min
.indexUse_header_min {
  margin-top: 68px;
  height: 120px;
  background: #4eeec8;
  overflow: hidden;
  > div {
    line-height: 120px;
  }
  .myHeaderImg {
    margin-top: 20px;
    width: 80px;
    height: 80px;
  }
  .myName {
    margin-left: 20px;
    padding-top: 28px;
    font-size: 18px;
    p {
      height: 30px;
      line-height: 30px;
      color: white;
    }
    p:nth-of-type(2) {
      margin-top: 10px;
    }
    img {
      width: 30px;
      margin-right: 10px;
      height: 30px;
      cursor: Pointer;
    }
  }
  .fn {
    margin-left: 20px;
    padding-top: 22px;
    p {
      cursor: Pointer;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding-left: 14px;
      padding-right: 14px;
      border: 1px solid white;
      background: rgba(255, 255, 255, 0.2);
      color: white;
      margin-right: 18px;
      border-radius: 4px;
    }
  }
  .fns {
    img {
      margin-right: 8px;
    }
    p {
      margin-right: 0;
      margin-left: 18px;
    }
  }
}
</style>